
.sliderimg{
    width: 450px;
    height: 500px;
    object-fit: contain;
  }
.padding{
    padding: 20px;
}

.customised{
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    width: 700px;
}

.foodhack{
    margin-top: 30px;
}
.padded{
    padding-left: 10px;
    padding-right: 10px;
}

.gallery_f_inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-flow: dense;
    margin: 0;
}

.wide4 {
    grid-column: span 4;
    margin: none;
}

.wide2 {
    grid-column: span 2;
}

.tall2 {
    grid-row: span 2;
}
.adjustedsmall{
    margin-top: 25px;
}


.wide3custom {
    grid-column: span 3;
    grid-column-start: 2;
    grid-column-end: span 3;
}

.wide3customleft {
    grid-column: span 3;
    grid-column-start: 1;
    grid-column-end: span 3;
}

img{
    border-radius: 5px;
    display: inline-block;
}





@media screen and (max-width: 768px) {

    .foodhack{
        margin-top: 10px;
    }
    .gallery_f_inner {
        display: block;

    }
    .adjustedsmall{
        margin-top: none;
    }
    .customised{
  
        width: 300px;
    }
    
}


li {
    margin-bottom: 5px;
}

.row {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 0 0px;
    margin: -5px -5px;
}

/* Flexbox container for two-column layout */
.two-column {
    display: flex;
    flex-wrap: wrap;
}

/* Set the width of the columns */
.two-column img {
    flex: 1 1 40%;
    max-width: 40%;
    /* make the image smaller */
}

.two-column p {
    flex: 1 1 60%;
    /* padding: 0 20px; Adjust the padding as needed */
    /* padding-top: 25px; */
}

.two-column30 {
    display: flex;
    flex-wrap: wrap;
}


.two-column40 {
    display: flex;
    flex-wrap: wrap;
}

/* Set the width of the columns */
.two-column40 img {
    flex: 1 1 40%;
    max-width: 40%;
}

.two-column40 p {
    flex: 1 1 60%;
}

.imageslide{
    /* make the image fit inside the container it is in . resize the image*/
    width: 400px;
    height: 350px;
    object-fit: contain;


}

.two-column60 {
    display: flex;
    flex-wrap: wrap;
}
.two-column60 .left-column {
    flex: 1 1 40%;
}

.two-column60 .right-column {
    flex: 1 1 60%;
}

/* Optional: Add some space between the two columns */
.center {
    gap: 20px; /* Adjust the gap size as needed */
}

.adjustedp{
    /* add spacing from the top */
    margin-top : 40px;
}
.adjusted{
    /* add spacing from the top */
    margin-top : 100px;
}

.nexusimg{
    width: 200px;
}
.embeddedsimg{
    width: 425px;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}

.marsimg{
    border-radius: 10px; 
}
@media screen and (max-width: 768px) {

    .adjustedp{
        display: none;
    }
    .adjusted{
        display: none;
    }
    .imageslide{
        width: 200px;
        height: 100px;
        object-fit: contain;
    }
    .embeddedsimg{
        width: 300px;
    }

    .two-column img{
        flex: 1 1 100%;
        max-width: 100%;
    }
    .two-column p {
      flex: 1 1 100%;
      max-width: 100%;
    }

    .two-column40{
        flex-direction: column;
    }

    .two-column40 img,
    .two-column40 p {
      flex: 1 1 100%;
      max-width: 100%;
    }

    .two-column60 {
        flex-direction: column;
    }
    
    .two-column60 img,
    .two-column60 p {
      flex: 1 1 100%;
      max-width: 100%;
    }
}


