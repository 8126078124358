/* Main Container */
.SkillsGrid {
  text-align: center;
  padding: 10px;
}

/* Category Grid for Layout */
.categoryGrid {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

/* Category Section */
.categorySection {
  border-radius: 16px;
  padding: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
  flex: 1;
  min-width: 300px;
}

/* Skills Container for Cards Layout */
.skillsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

/* Individual Skill Card */
.skillTile {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 16px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
  transition: transform 0.2s;
}

.skillTile:hover {
  transform: translateY(-5px);
  background-color: #e3f2fd;
}

/* Skill Image */
.skillImage {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
