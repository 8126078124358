/* General Styles */
.lmn-h2 {
  color: #212529;
  padding: 10px;
  margin: 30px 30px;
  width: 100%;
}

.lmn-flex {
  display: flex;
}

.center {
  text-align: center;
}
.small {
  font-size: small;
}

/* Two Column Layout */
.two-column img {
  flex: 1 1 25%;
  max-width: 25%;
}

.two-column p {
  flex: 1 1 75%;
  padding: 0 30px;
}

/* Skills Container with Grid */
.skills-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Default 5 columns */
  grid-gap: 5px;
  justify-items: center;
  align-items: center;
}

.tiling {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.318);
  border-radius: 10px;
  box-shadow: 0 0 1px rgba(187, 183, 183, 0.2);
  text-align: center;
  padding: 5px;
}

.tiling img {
  border-radius: 20px;
  width: 60px;
  height: 60px;
}

/* Reusable Box Styles */
.commonBackground {
  background: rgba(255, 255, 255, 0.318);
  border-radius: 20px;
  box-shadow: 0 0 1px rgba(187, 183, 183, 0.2);
  padding: 10px;
  margin: 10px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .col .lmn-h2 {
    font-size: medium;
  }

  .two-column img,
  .two-column p {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 0;
  }

  .skills-container {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for mobile */
    grid-gap: 10px;
  }

  .tiling {
    margin: 10px;
  }

  .me {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .commonBackground, .lmn-flex {
    display: flex;
    flex-direction: column;
  }

  .image-column,
  .text-column {
    order: unset;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Circular Images */
.pics {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

/* Testimonials Layout */
.testimonials {
  display: flex;
  justify-content: space-between;
}

.image-column {
  flex-basis: 20%;
}

.text-column {
  flex-basis: 70%;
}
.row-container {
  display: flex;
}

.awards-container {
  flex: 0 0 40%;
}

.testimonials-container {
  flex: 0 0 60%;
}

@media screen and (max-width: 768px) {
  .row-container {
    flex-direction: column;
  }
  .awards-container,
  .testimonials-container {
    flex: 1 1 100%;
  }
}
