.App {
  /* background-image: -webkit-linear-gradient(0deg, #e18df6 0%, #e5b0f1 100%);   */
  background-image: url(./background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  z-index: -2;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
  font-style: italic;
}

.Container{
  /* glassmorphism effect here */
  background: rgba(255, 255, 255, 0.49);
  border-radius: 20px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  color: black;
  z-index: -1;
  margin: 10px;
  padding: 5px;
  padding-bottom: 30px;
  margin-top: 100px;
}


footer {
  /* use glassprophism here */
  background: rgba(255, 255, 255, 0.264);
  border-radius: 20px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  color: black;
  z-index: -1;
  margin: 10px;
  text-align: center;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .footer-content { 
    padding-top: 20px;
    font-size: small
   }  
   .footer-icons{
      font-size: small
   }

    .footer-icons a{
      margin-bottom: 10px;
    }

    .Container{
      margin-top: 90px;
    }
}




.footer-inner {
  display: flex;
  justify-content: center;
}

.footer-content {
  max-width: 600px;
}

.footer-icons {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.footer-icons li {
  margin: 0 15px;
}

.footer-icons a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: rgb(55, 54, 54);
}

.footer-icons i {
  font-size: 24px;
  margin-bottom: 10px;
}

.footer-icons i.fa-circle {
  font-size: 6px;
  margin-bottom: 5px;
}

/* Animation Styles */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.typewriter {
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange;
  /* The typewriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em;
  /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end;
}

.slide-in-animation {
  opacity: 0;
  animation: slideIn 1s ease-in-out forwards;
}
